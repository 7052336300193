/**
 * Banner component
 */

import React from "react";
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';

const bannerStyle = {
	backgroundColor: 'rgb(45, 104, 196)',
	position: 'relative',
	width: '100%',
};

const h1Style = {
	fontSize: 42,
	textAlign: 'center',
	maxWidth: 340,
	margin: 'auto',
};

export default class Banner extends React.PureComponent {
	render() {
		const { children, actionText } = this.props;
		return (
			<section className="banner" style={bannerStyle}>
				<div className="banner text" style={{ color: 'white' }}>
					<h1 style={h1Style}>{children}</h1>
					{!!actionText ? (
						<div className="action">
							<Button basic inverted as={Link} to={`/contact/`}>{actionText}</Button>
						</div>
					) : null}
				</div>
			</section>
		);
	}
}
