export const projectList = [
	// 
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection of a 2-Storey Envelope Control Semi-Detached Dwelling House with an Attic and Swimming Pool at Namly Garden',
		ownerArchitect: 'MJKY ARCHITECTS',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection Of 2-Storey Envelope Control Semi-Detached Dwelling House With An Attic And Swimming Pool at Worthing Road',
		ownerArchitect: 'MJKY ARCHITECTS',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Additions and Alterations of an Existing 2-Storey Semi-Detached Dwelling House to a 2-Storey Envelope Control Semi-Detached Dwelling House with an Attic at Sixth Avenue',
		ownerArchitect: 'STUDIO ISH',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Reconstruction to an Existing 3-Storey Intermediate Terrace Dwelling House to a 3-Storey Envelope Control Intermediate Terrace Dwelling House at Daisy Road',
		ownerArchitect: 'OWMF ARCHITECTURE',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed New Erection of a 7-Storey Institution Building at Lorong 9 Geylang',
		ownerArchitect: 'LAUD ARCHITECTS',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Reconstruction of an existing 2-Storey Intermediate Terrace to 2-Storey Intermediate Terrace Dwelling House with an Attic at West Coast Terrace',
		ownerArchitect: 'ARCHITECTS SG LLP',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Re-Construction to a 2-Storey Envelope Control Intermediate Terrace Dwelling House with Attic at Macpherson Road',
		ownerArchitect: 'TWA',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Addition & Alteration to Existing Conserved 2 Storey Singapore Philatelic Museum And 3-Storey Ancillary Building at Coleman Street',
		ownerArchitect: 'Design International Architects LLP',
		category: 'Conservation',
		type: 'institutional'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Reconstruction of The Existing 2-Storey Detached Dwelling House to A 2-Storey Envelope Control Detached Dwelling House with An Attic at Frankel Avenue',
		ownerArchitect: 'Mint Design',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Works for the Proposed Additions & Alterations to a 2-Storey Envelope Control Intermediate Terrace Dwelling House with New Attic at Jalan Selendang Delima',
		ownerArchitect: 'TWA',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Additions & Alterations to The Existing 2-Storey Semi-Detached Dwelling House with New Basement Car Porch at Wishart Road',
		ownerArchitect: 'TWA',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Reinstatement Work to Existing Damaged RC Column At Sims Avenue',
		ownerArchitect: 'Chop Hup Chong Food Industries Pte Ltd',
		category: 'Reinstatement',
		type: 'commercial'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection of A 2-Storey Envelope Control Semi-Detached Dwelling House with Mezzanine and Attic at Nemesu Avenue',
		ownerArchitect: 'TWA',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection of A 2-Storey Envelope Control Intermediate Terrace Dwelling House with An Attic at Casuarina Road',
		ownerArchitect: 'ARCHIDEAS DESIGN',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection of A 3-Storey Envelope Control Intermediate Terrace Dwelling House with An Attic at Jalan Lada Puteh',
		ownerArchitect: 'ARCHIDEAS DESIGN',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Reconstruction of An Existing 2-Storey Intermediate Terrace Dwelling House with New Attic Maria Avenue',
		ownerArchitect: 'FFUSION ARCHITECTS',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection Of A 2-storey Envelope Control Semi-detached Dwelling House With Mezzanine And Attic At Nemesu Avenue',
		ownerArchitect: 'TWA',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection Of A 2-storey Envelope Control Intermediate Terrace Dwelling House With An Attic At Casuarina Road',
		ownerArchitect: 'ARCHIDEAS DESIGN',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection Of A 3-storey Envelope Control Intermediate Terrace Dwelling House With An Attic At Jalan Lada Puteh',
		ownerArchitect: 'ARCHIDEAS DESIGN',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Reconstruction Of An Existing 2-storey Intermediate Terrace Dwelling House With New Attic At Maria Avenue',
		ownerArchitect: 'FFUSION ARCHITECTS',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Replacement Of 2nd Storey Timber Structures To Steel Structures To Existing 2-storey Corner Terrace Shophouse At Upper Bukit Timah Road',
		ownerArchitect: 'Private Property',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Additions & Alterations to Existing Single User General Industrial Factory on at Senoko South Road (Sembawang Planning Area) & Proposed New Link Bridge between No. 6 Senoko South Road and No. 14 Senoko South Road',
		ownerArchitect: 'Chop Hup Chong Food Industries Pte Ltd / Arya Architects',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection of a 2 Storey Envelope Control Semi Detached Dwelling House with an Attic at York Place',
		ownerArchitect: 'Private Property',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection of a 2 Storey Semi Detached Dwelling House with Attic on at Jalan Sappan Ang Mo Kio Planning Area',
		ownerArchitect: 'World-Place Designs Pte Ltd Mr Chin, William',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Addition Alteration Work To Erect A 2 Storey Rear Extension With New Carporch And Patio To Existing 2 Storey Corner Terrace at Jalan Batalong East',
		ownerArchitect: 'Private Property',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection Of A 2 Storey Detached Dwelling House With 1 Storey Basement An Attic And A Swimming Pool On at Jalan Insaf',
		ownerArchitect: 'Foma Architects LLP',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Structure Works For Proposed Erection Of Out House at Leedon Road',
		ownerArchitect: 'Private Property',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Additions And Alterations To Existing 2 Storey Semidetached Dwelling House With An Attic at Clover Crescent',
		ownerArchitect: 'ARCHIDEAS Design Inc Mr HO',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Erection Of A 2 Storey Envelope Control Detached Dwelling House at Tavistock Avenue',
		ownerArchitect: 'TWA',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 1,
		projectDescription: 'Proposed Additions and Alterations to The Existing 3-Storey Conserved Shophouse with New Roof Mezzanine, Use as Association On At 70-1 Club Street (Chinatown-Telok Ayer Conservation Area)',
		ownerArchitect: 'Sit Giap Company Pte Ltd / Artprentice (Mr Chua)',
		category: 'Conservation',
		type: 'institutional'
	},
	{
		originalOrder: 19,
		projectDescription: 'Proposed Conservation of Existing 2-Storey Shophouse and Additions and Alterations at Purvis Street for M/s British & Malayan Trustees Ltd',
		ownerArchitect: 'Architects Vista',
		category: 'Conservation',
		type: 'institutional'
	},
	{
		originalOrder: 29,
		projectDescription: 'Proposed Restoration to Existing 2-Storey Pre-War House at No.73, No.75 and No.79 at Victoria Street, Singapore',
		ownerArchitect: 'Private Property',
		category: 'Conservation',
		type: 'residential'
	},
	{
		originalOrder: -1,
		projectDescription: 'Proposed Reconstruction of an existing 2 storey semi-detached dwelling house to a 2 storey semi-detached dwelling house with an attic at Jalan Chelagi',
		ownerArchitect: 'Private Property',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 1,
		projectDescription: 'Proposed Additions & Alterations to the Existing 5-Storey Detached Warehouse Building at Toh Guan Road East (Jurong East Planning Area',
		ownerArchitect: 'Lua Architects Associates Pte Ltd / Owner Tye Soon Limited',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 2,
		projectDescription: 'Proposed Additions & Alterations to the Existing 2-Storey Intermediate Terrace Dwelling House with Provision of Attic on at Binchang Rise (Bishan Planning Area)',
		ownerArchitect: 'TWA - Mr Tay',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 3,
		projectDescription: 'Proposed Additions & Alterations to the Existing Single Storey Corner Terrace Dwelling House with an Attic at Lorong Geylang',
		ownerArchitect: 'TWA – Mr Tay',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 4,
		projectDescription: 'Proposed Additions & Alteration to an existing 4 Storey Commercial Building with 1st Storey Retail and Office at 2nd, 3rd, and 4th Storey on at Geylang Road Singapore 388344',
		ownerArchitect: 'Lua Architects Associates Pte Ltd / Rohato Investments Pte Ltd / QBest Pte Ltd',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 5,
		projectDescription: 'Proposed Addition and Alteration of an Existing 2 Storey Conservation Shophouse on Lot(s) 06459 MK25 at Tanjong Katong Road Singapore 437053',
		ownerArchitect: 'Bee Cheng Hiang Hup Chong Foodstuff Pte Ltd / Lua Architects Associates',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 6,
		projectDescription: 'Proposed Additions & Alterations to the existing 2-Storey Corner Terrace Dwelling House at Tosca Terrace.',
		ownerArchitect: 'Genome Architects / K L Cheong & Associates (Main Contractor: C.S. Builders Pte Ltd)',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 7,
		projectDescription: 'Proposed Additional & Alteration Works at Cheong Chin Nam Road, Singapore 599735',
		ownerArchitect: 'Bee Cheng Hiang Hup Chong Foodstuff Pte Ltd / B K Tan Consultants',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 8,
		projectDescription: 'Re-Construction of Apron Slab, Driveway, Grease Interceptor, Manholes, Services at Tuas Bay Walk on Lot(s) 03015 MK07',
		ownerArchitect: 'B K Tan Consultants / Chop Hup Chong Food Industries Pte Ltd',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 9,
		projectDescription: 'Erection of one set New Escalator at 2nd Storey Block A for Nanyang Polytechnic',
		ownerArchitect: 'Nanyang Polytechnic',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 10,
		projectDescription: 'Renovation Works to Warewash Area to CIAS',
		ownerArchitect: 'CIAS Flight Kitchen',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 11,
		projectDescription: 'Addition & Alteration Works to Theatre for the Arts at Block A Nanyang Polytechnic',
		ownerArchitect: 'Nanyang Polytechnic',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 12,
		projectDescription: 'Addition & Alteration Works to Freezer Chiller & Thawing Room at CIAS',
		ownerArchitect: 'CIAS',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 13,
		projectDescription: 'Addition & Alteration Works to Existing 2-Storey Corner Terrace with Attic at Jalan Khati',
		ownerArchitect: 'Private Property',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 14,
		projectDescription: 'Addition & Alteration Works to Seletar Country Club',
		ownerArchitect: 'Seletar Country Club',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 15,
		projectDescription: 'Addition & Alteration Works to Nanyang Polytechnic',
		ownerArchitect: 'Nanyang Polytechnic',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 16,
		projectDescription: 'Proposed A & A Works and Cyclical Maintenance Works at Kebun Baru Community Club',
		ownerArchitect: 'CPG Facilities Management Pte Ltd / People’s Association',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 17,
		projectDescription: 'Proposed Change of Use from Shop to Betting Outlet to Unit #01-364 at Lengkok Bahru',
		ownerArchitect: 'Ben Design Architects / Singapore Turf Club',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 18,
		projectDescription: 'Proposed A & A Involving Replacement of Existing Boundary Fence, Walls and Car-Parks at Habitat Ardmore Park',
		ownerArchitect: 'JK Architects Mr John Killick',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 20,
		projectDescription: 'Proposed Addition of an Open Shed to Hitachi Parts Distribution Centre at Liu Fang Road, Singapore for Hitachi Construction Machinery (S) Pte Ltd',
		ownerArchitect: 'CMP Consultants Pte Ltd',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 21,
		projectDescription: 'Proposed Alterations & Additions to Interiors at Ardmore Park',
		ownerArchitect: 'Fabrica Pte Ltd',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 22,
		projectDescription: 'Proposed Refurbishment of Existing Lift Lobbies at POSB Centre',
		ownerArchitect: 'Ape-X Group',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 23,
		projectDescription: 'Proposed Renovation of POSBank Branch at Suntec City Tower No.02-008',
		ownerArchitect: 'DP Architects Pte Ltd',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 24,
		projectDescription: 'Proposed Refurbishment to Pearls Centre on Lot 178 TS22 at Eu Tong Sen St for The Management Corporation Strata Title Plan, 512 Singapore',
		ownerArchitect: 'KPK Quantity Surveyors',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 25,
		projectDescription: 'Proposed Reconstruction of Existing 2-Storey with Basement Semi-Detached Dwelling House at Jalan Pemimpin',
		ownerArchitect: 'K C Fong Consultants',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 26,
		projectDescription: 'Proposed Renovation to Existing Lift Lobby (2nd to 17th Storey) on 178 (Now 658) 12-69PT775 25 at Ardmore Park',
		ownerArchitect: 'ADDP Architects',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 27,
		projectDescription: 'Proposed 3-Storey Extension & Additions / Alterations to the Existing Temple on Lot 3535 MK 23 at Arumugam Road',
		ownerArchitect: 'Ben Design Architects / Sheng Hong Temple Association',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 28,
		projectDescription: 'Proposed Additions & Alterations to the Existing 4-Storey M-Ansor Mosque at Bedok North Avenue 1',
		ownerArchitect: 'Cesma International Pte Ltd / OSV Consultants Pte Ltd',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 30,
		projectDescription: 'Proposed Reconstruction to Existing 2-Storey Semi-detached House at Watten Heights for Madam Ou',
		ownerArchitect: 'Pan-Indo 666 & Associates Architects',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 31,
		projectDescription: 'Proposed Addition & Alteration to POSBANK at Unit No.02-05 at Pidemco Centre on Lot 539 7S at South Bridge Road',
		ownerArchitect: 'Architect Studio',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 32,
		projectDescription: 'Proposed Additions & Alterations to Existing 1st Storey Lobby Car Poach, Driveway, Garden and Carpark Shelter at Ardmore Park',
		ownerArchitect: 'ADDP Architects',
		category: 'Additions & Alterations',
		type: 'residential'
	},
	{
		originalOrder: 33,
		projectDescription: 'Proposed Extension & Addition/Alteration to Existing 3-Storey Temple with Basement Carpark on Lot 2524 MK23 Jalan Eunos / Sims Avenue East',
		ownerArchitect: 'Mangala Vihara (Buddhist Temple)',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 34,
		projectDescription: 'Proposed Additions & Alterations to Existing Chinese Temple at Tessensohn Road on Lot 195-5PT, 195-2, 195-3PT and 196-1PT T.S.18',
		ownerArchitect: 'Wah Pin Design Group',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 35,
		projectDescription: 'Proposed Additions & Alterations to Maintenance Shop at Pulau Ayer Merbau on Plot A4446 MK 34',
		ownerArchitect: 'Petrochemical Corporation of Singapore Pte Ltd',
		category: 'Additions & Alterations',
		type: 'commercial'
	},
	{
		originalOrder: 1,
		projectDescription: 'Proposed Erection of 2 Blocks of 5 Storey Residential Flat (Total: 24 Units) With Swimming Pool, Carpark at First Storey and Attic at Hillside Drive',
		ownerArchitect: 'JVA Venture Pte Ltd',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 2,
		projectDescription: 'Proposed Erection of a Block of 5 Storey Residential Flat (Total: 19 Units) With Swimming Pool, Carpark at First Storey and Roof Terrace at Lorong M Telok Kurau',
		ownerArchitect: 'JVA Venture Pte Ltd',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 3,
		projectDescription: 'Proposed Erection of 11 Units of 2 Storey Terrace House with Attic, Basement Carpark and Swimming Pool at Lorong L Telok Kurau',
		ownerArchitect: 'JVA Venture Pte Ltd',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 4,
		projectDescription: 'Proposed Erection of 3 Pairs of 2 Storey Semi-Detached House with Attic and Basement Carpark and Swimming Pool at Brighthill Crescent',
		ownerArchitect: 'JVA Venture Pte Ltd',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 5,
		projectDescription: 'Proposed Erection of A 6-Storey Single-User General Industrial Factory On at Senoko South Road (Sembawang Planning Area)',
		ownerArchitect: 'Owner Chop Hup Chong Food Industries Pte Ltd / Architect: Wilson Ling',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 6,
		projectDescription: 'Proposed Erection of A 3-Storey Corner Terrace Dwelling House with Attic on at Duke’s Road',
		ownerArchitect: 'TWA',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 7,
		projectDescription: 'Proposed Erection of A 3 Storey Intermediate Terrace House with an Attic at Poh Huat Road',
		ownerArchitect: 'Architect: James Ong HS',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 8,
		projectDescription: 'Proposed Erection of A 2-Storey Semi-Detached Dwelling House with an Attic on at Burghley Drive',
		ownerArchitect: 'Architect James Ong HS',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 9,
		projectDescription: 'Proposed New Erection of A 3-Storey Detached Dwelling House with an Attic and Swimming Pool at Mayflower Drive',
		ownerArchitect: 'ADX Architects',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 10,
		projectDescription: 'Proposed Erection of A 2-Storey Intermediate Terrace Dwelling House with an Attic at Carisbrooke Grove',
		ownerArchitect: 'CSL Architects',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 11,
		projectDescription: 'Proposed Erection of A 2-Storey Semi-Detached Dwelling House with Attic and Swimming Pool at Braemar Drive',
		ownerArchitect: 'TWA',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 12,
		projectDescription: 'Proposed Erection of A 2-Storey Intermediate Terrace Dwelling House with Attic at Lasia Avenue',
		ownerArchitect: 'TWA',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 13,
		projectDescription: 'Proposed Reconstruction of a Three Storey Detached Dwelling House with Roof Terrace at Jalan Kechubong ',
		ownerArchitect: 'Ztech Architects / JVA Venture Pte Ltd / 3-Link Engineering Pte Ltd',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 14,
		projectDescription: 'Proposed Reconstruction of 1 Unit of 3-Storey Corner Terrace House with Roof Terrace at Hendry Close',
		ownerArchitect: 'Mint Design',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 15,
		projectDescription: 'Proposed Reconstruction of 1 Unit of 3-Storey Corner Terrace House at Highland Road',
		ownerArchitect: 'Mint Design',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 16,
		projectDescription: 'Proposed New Erection of One Unit of Three Storey Semi-Detached Dwelling House with Roof Terrace at Eden Grove',
		ownerArchitect: 'Ztech Architects / JVA Venture Pte Ltd / 3-Link Engineering Pte Ltd',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 17,
		projectDescription: 'Design & Built Lift Shaft for Food Factory at Tuas Bay Walk',
		ownerArchitect: 'Eng Soon Bean Curd Mgf. Pte Ltd',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 18,
		projectDescription: 'Design & Built a Existing 2-Storey Terrace House at Jalan Labu Ayer',
		ownerArchitect: 'Private Property',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 19,
		projectDescription: 'Reconstruction of a 2-Storey Semi-Detached House with Attic and Basement at Jalan Rukam',
		ownerArchitect: 'Private Property',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 20,
		projectDescription: 'Proposed Erection of One Pair of 2-Storey Semi-Detached Dwelling House Each with Attic at Jalan Binchang',
		ownerArchitect: 'Architect Genesis',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 21,
		projectDescription: 'Construction of Building Blk 104, 126, 127, 128, 310A, 319, 320, 321 & Ancillary Works at Changi East',
		ownerArchitect: 'LEO',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 22,
		projectDescription: 'Refurbishment Works to Two Blocks of 7-Storey Flatted Factories (Blk 512 & 514) One Bin Centre, Design & Build Covered Link-way Metal Canopies & Pump House with Sprinkler System & Sprinkler Water Tanks at Chai Chee Lane',
		ownerArchitect: 'JTC',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 23,
		projectDescription: 'Proposed Erection of A Part 3 / Part 4 – Storey Single User Occupier Light Industrial Factory on Lots 334 PT, 33 PT, 358 PT, 359, 360 PT, 361 PT, 396 PT MK 27 at Changi South Lane',
		ownerArchitect: 'Kingsmen',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 24,
		projectDescription: 'Project LE(D) 1080 CO1 Design & Build a Tactical Simulator Building at Sungei Gedon Camp',
		ownerArchitect: 'Pan-Indo Architects International',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 25,
		projectDescription: 'Main Building Works for Proposed Erection of a Three Storey Mosque with Ancillary Facilities and A Carparks at First Storey at Serangoon North Avenue 2, Singapore',
		ownerArchitect: 'James Yip & Partners',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 26,
		projectDescription: 'Proposed Erection of a Part 2/and Part Four Storey Singles Purpose Single Occupy Factory at Woodland Sector 1',
		ownerArchitect: 'ADDP Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 27,
		projectDescription: 'Proposed Reconstruction to the Existing Single Storey Terrace Dwelling House to a 2-Storey Terrace Dwelling House with Basement and Attic at Casurina Road',
		ownerArchitect: 'Chan Yat Weng Architects',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 28,
		projectDescription: 'Proposed Erection of One Unit 2-Storey Detached Dwelling House with a Basement and an Attic and Four Units of 2-Storey Strata Bungalows each with an Attic and a Swimming Pool at Walton Road',
		ownerArchitect: 'RSP Architects Planners & Engineers (Pte) Ltd',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 29,
		projectDescription: 'Proposed Erection of One (1) Block of 7-Storey Flatted Warehouse Building with a Basement Carpark at Kallang Pudding Road',
		ownerArchitect: 'Architects 61 Pte Ltd',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 30,
		projectDescription: 'Addition and Alterations Involving Addition of A 4-Storey Extension School at Marine Terrace/Marine Parade Road',
		ownerArchitect: 'JK Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 31,
		projectDescription: 'Proposed Erection of a 2-Storey Bungalow with Attic at Coldstream Avenue',
		ownerArchitect: 'Chan Yat Weng Architects',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 32,
		projectDescription: 'Proposed Erection of Single Storey and Part 2-Storey Chinese Temple on Lot 1744PT Mukim 19 at Yishun Industrial Park A on HDB Land within Temple Site 2',
		ownerArchitect: 'WP Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 33,
		projectDescription: 'Proposed Erection of Single Storey and Part 2-Storey Chinese Temple with Basement on Lot 2160 PT. HDB Land Mukim 23 at Bedok Reservoir Road',
		ownerArchitect: 'WP Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 34,
		projectDescription: 'Proposed Erection of a Single Storey Chinese Temple on Lot 1-18 MK 29 at Tampines Road, Singapore 1851',
		ownerArchitect: 'Wah Pin Design Group / H. T. Oey Chartered Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 35,
		projectDescription: 'Proposed 2-Storey Vehicle Inspection Centre on Private Lot A14602 at Pioneer Rd',
		ownerArchitect: 'Group Design Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 36,
		projectDescription: 'Proposed 2-Storey Vehicle Workshop on Lot 1971 PT MK 7 at Pioneer Road',
		ownerArchitect: 'Group Design Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 37,
		projectDescription: 'Proposed 3-Storey Town House Development at Pasir Panjang,',
		ownerArchitect: 'KPK Quantity Surveyors',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 38,
		projectDescription: 'Proposed SAF Driving Circuit at Kaki Bukit Road 5',
		ownerArchitect: 'MINDEF',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 39,
		projectDescription: 'Proposed Erection of a 2-Storey Detached Dwelling House at Jalan Berjaya',
		ownerArchitect: 'Architechnique Architects PM Surveyors',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 40,
		projectDescription: 'Proposed US Embassy Mission Residence of 2-Storey with a Basement and Ancillary Facilities at Leedon Park',
		ownerArchitect: 'U.S. Embassy',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 41,
		projectDescription: 'Proposed Erection of 2-Storey Chinese Temple with Basement on Lot 690PT (Formerly Lots 139PT, 140PT & 510PT) on HBD Land MK 14 at Bangkit Road',
		ownerArchitect: 'H. T. Oey Chartered Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 42,
		projectDescription: 'Proposed Abattoir Complex Comprising of a Single-Storey Slaughter Hall and 2-Storey Ancillary Facilities on Lot 602PT MK 6 at Jalan Buroh for Primary Industries Pte Ltd',
		ownerArchitect: 'J Y Huang Associates / Steler Consultancy Pte Ltd',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 43,
		projectDescription: 'Proposed 3-Storey End Terrace Dwelling House with a Clinic on the 1st Storey on Lot 2528 MK 23 at Jalan Eunos, Singapore 1542',
		ownerArchitect: 'Chua Ka Seng & Partners Chartered Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 44,
		projectDescription: 'Proposed Erection of a 2-Storey Chinese Temple on Lot 2532 and 2534 Mukim 23 at Jalan Eunos and Jalan Yasin, Singapore',
		ownerArchitect: 'ID Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 45,
		projectDescription: 'Proposed Erection of a Pair of 3-Storey Semi-Detached House at Jln Grisek',
		ownerArchitect: 'Architects Group Associates',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 46,
		projectDescription: 'Proposed Erection of 3-pairs 2-Storey Semi-Detached House at Meragi Close',
		ownerArchitect: 'Ang Kheng Leng & Associates Architects',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 47,
		projectDescription: 'Proposed Erection of a 2-Storey Detached House at Dunbar Walk',
		ownerArchitect: 'Private Property',
		category: 'Building Works',
		type: 'residential'
	},
	{
		originalOrder: 48,
		projectDescription: 'Proposed Part 2, Part 1 Storey Factory on Lot 1849 MK-28 at Tampines Ind. Park – Hexagon (S) Pte Ltd',
		ownerArchitect: 'Won Architects',
		category: 'Building Works',
		type: 'commercial'
	},
	{
		originalOrder: 49,
		projectDescription: 'Construction of New Gate Facilities and Holding Area for Chassis at Keppel / Kampong Bahru Road',
		ownerArchitect: 'PSA',
		category: 'Building Works',
		type: 'commercial'
	}
];
