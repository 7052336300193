import React from 'react';
// import LightBox from './lightbox.jsx';
import { Icon } from 'semantic-ui-react';
import Image from "gatsby-image"

function modulo(dividend, divisor) {
	// % operator is remainder, not modulo
	return ((dividend % divisor) + divisor) % divisor;
}

function wait(time) {
	return new Promise(resolve => {
		setTimeout(resolve, time);
	});
}

export class Carousel extends React.Component {
	state = { paused: false, activeIndex: 0, isEnd: false }

	componentDidMount() {
		this.setState({ intervalId: setInterval(this.handleNext, 4000) });
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalId);
	}

	handleNext = () => {
		if (this.state.activeIndex + 1 >= this.props.imageArray.length) {
			this.setState({ activeIndex: 0, isEnd: true });
			// await wait(50); // change to cleaner update callback instead...
			wait(50).then(() => this.setState({ activeIndex: 1, isEnd: false }));
		} else {
			const next = modulo(this.state.activeIndex + 1, this.props.imageArray.length);
			this.setState({ activeIndex: next });
		}
	}

	handlePrevious = () => {
		if (this.state.activeIndex <= 0) {
			this.setState({ activeIndex: this.props.imageArray.length - 1, isEnd: true });
			// await wait(50);
			wait(50).then(() => this.setState({ activeIndex: this.props.imageArray.length - 2, isEnd: false }));
			// this.setState({ activeIndex: this.props.imageArray.length - 2, isEnd: false });
		} else {
			const previous = modulo(this.state.activeIndex - 1, this.props.imageArray.length);
			this.setState({ activeIndex: previous });
		}
	}

	render() {
		const { imageArray } = this.props;
		const { activeIndex, isEnd } = this.state;
		if (imageArray[imageArray.length - 1] !== imageArray[0]) {
			imageArray.push(imageArray[0]);
		}
		const viewstripstyle = {
			left: `-${activeIndex}00%`,
			transition: isEnd ? 'none' : 'left 0.5s ease',
			width: `${imageArray.length}00%`,
			position: 'relative',
			display: 'flex',
			top: '0',
			height: '100%',
		};
		return (
			<div className="carousel" style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
				{/*<div className="carouselControl" style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 1 }}>*/}
					<Icon name='play' bordered size="large" inverted onClick={this.handleNext} style={{ position: 'absolute', right: 0, top: '50%', zIndex: 1 }}/>
					<Icon name='play' bordered size="large" inverted flipped='horizontally' onClick={this.handlePrevious} style={{ position: 'absolute', left: 0, top: '50%', zIndex: 1 }}/>
				{/*</div>*/}

				<div className="viewBox" style={{ position: 'relative', overflow: 'hidden', transform: 'translate3d(0, 0, 0)' }}>
					{/*<div className="viewstrip" style={{ position: 'relative', right: 0, background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet, red)', height: '100px' }}></div>*/}
					<div className="viewstrip" style={viewstripstyle}>
						{imageArray.map((image, index) => {
							return (
								<div key={index} style={{ width: '100%' }}>
									<Image fluid={image}/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
