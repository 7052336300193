import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import URL from "../components/url"
import useViewPort from '../utils/useViewPort'
import { Grid, Button, Icon, Dimmer, Divider } from 'semantic-ui-react';
import { useStaticQuery, graphql, Link } from "gatsby"
import { Carousel } from '../components/carousel.jsx';
import { projectList } from '../assets/json/projectlist.js';
import Img from "gatsby-image"

const ServiceSection = (props) => {
  const { right, title, children, primaryIcon, secondaryIcon, color = 'red' } = props;
  const { isMobile } = useViewPort();

	const content = (<div>
		<h3 style={{ fontSize: '18px', margin: '0.5rem' }}>{title}</h3>
		<p>{children}</p>
	</div>);

	const icon = (<div style={{ padding: 10 }}>
    <Icon.Group size='huge'>
      <Icon className={primaryIcon}/>
      <Icon className={secondaryIcon} color={color} corner/>
    </Icon.Group>
  </div>);

  if (isMobile) {
    return (
      <Grid.Row>
        <Grid.Column>
          {icon}
          {content}
        </Grid.Column>
      </Grid.Row>
    );
  }

  const cols = [
    (<Grid.Column key="content" width={13}>
      {content}
    </Grid.Column>),
    (<Grid.Column key="icon" floated={right && 'right'} width={3}>
      {icon}
    </Grid.Column>),
  ];
  if (right) { cols.reverse(); }

	return (
		<Grid.Row>
			{cols}
		</Grid.Row>
	);
};

class ImageDimmer extends React.Component {
	state = { active: false }

	handleShow = () => this.setState({ active: true });

	handleHide = () => this.setState({ active: false });

	render() {
		const { active } = this.state;
		const { dimmerMessage, dimmerTitle, url, fluid } = this.props;
		return (
			<div style={{ position: 'relative', zIndex: 0 }}>
				<Dimmer.Dimmable dimmed={active} onMouseOver={this.handleShow} onMouseOut={this.handleHide}>
					<Dimmer active={active}>
            <Link to={`/featured/${url}/`}>
							<h4>{dimmerTitle}</h4>
							{dimmerMessage}
            </Link>
					</Dimmer>
					<Img fluid={fluid} />
				</Dimmer.Dimmable>
			</div>
		);
	}
}

const FeaturedSection = ({ projects }) => {
  const { isMobile } = useViewPort();
  return (
    <section id="featured" className="content featured">
      <div name="feature">
        <Divider />
        <h2 style={{ textAlign: 'center' }}>Featured Projects</h2>
        <Grid columns={isMobile ? 1 : 3} style={{textAlign: 'center', marginBottom: '2rem'}} divided={false}>
          {projects.map(proj => (
            <Grid.Column key={proj.project}>
              <ImageDimmer dimmerMessage={proj.description} dimmerTitle={proj.project} url={proj.titleImage.relativePath.split('/')[1]} fluid={proj.titleImage.childImageSharp.fluid} />
            </Grid.Column>
          ))}
        </Grid>
        <div className="action">
          <Button basic color='red' as={Link} to={'/services/'}>Engage our services</Button>
        </div>
      </div>
    </section>
  );
}

function multilineText(string, delimitSlash = false) {
	const array = delimitSlash ? string.split(/\n|\s\/\s/) : string.split(/\n/);
	return (
		<div>
			{array.map((sub, idx) => (<p key={idx} style={{ margin: 0 }}>{sub}</p>))}
		</div>
	);
}

const ProjectTable = ({ category }) => (
  <div style={{ padding: '5px' }}>
    <h4 style={{ textAlign: 'center' }}>{category.toLocaleUpperCase()}</h4>
    <table style={{ borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Project Description</th>
          <th style={{ border: '1px solid black', padding: '5px', width: '25%' }}>Involvement</th>
        </tr>
      </thead>
      <tbody>
          {projectList.filter(o => o.category.includes(category)).map((o, idx) => {
            return (
              <tr key={idx} >
                <td>{idx + 1}</td>
                <td>{multilineText(o.projectDescription)}</td>
                <td style={{ border: '1px solid black', padding: '5px' }}>{multilineText(o.ownerArchitect, true)}</td>
              </tr>
            );
          })}
        </tbody>
    </table>
  </div>
)

const ServicesPage = (params) => {
  const url = `https://www.bca.gov.sg/BCADirectory/Company/Details/199706813H`;

  const data = useStaticQuery(graphql`
    query {
      allMetadataJson(filter: {feature: {eq: true}}) {
        nodes {
          project
          description
          titleImage {
            relativePath
            childImageSharp {
              fluid(traceSVG: {}, maxWidth: 1500, maxHeight: 1000) {
                tracedSVG
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);
  const featuredArray = data.allMetadataJson.nodes;
  const { isMobile } = useViewPort();
  return (
    <Layout {...params}>
      <div className="services page">

        <SEO title={`Services & Portfolio`} />
        <Banner actionText="Contact us">Our Services</Banner>
        <section className='content carousel'>
          <div>
            <Carousel imageArray={featuredArray.map(node => { return node.titleImage.childImageSharp.fluid; })}/>
          </div>
        </section>
        <section className="content description">
          <div>
            <p>With over 20 years of experience in both residential and commercial projects, Jinmac Pte Ltd provides extensive services for a wide range of building types. They includes: residential terrace, semi-detached, bungalows and strata housing; or commercial offices, warehouses and factories; or non-commercial projects such as temples and heritage buildings.</p>
            <p>As a BCA-licensed <URL href={url}>General Builder Class 1 (GB1)</URL> contractor, Jinmac Pte Ltd has undertaken many different levels of general building and civil engineering works. Our services includes:</p>
          </div>
        </section>
        <section className='content services'>
          <div>
            <Grid columns={isMobile ? 1 : 3} style={{textAlign: 'center', marginBottom: '2rem'}} divided={false}>
              <ServiceSection title="Residential Construction" primaryIcon="house damage" secondaryIcon="level up" color="orange">
								Teardown and rebuild of landed property residences that includes terraces, semi-detached, detached (bungalows) and strata landed houses (cluster housing).
              </ServiceSection>
              <ServiceSection title="Commercial &amp; Institutional Construction" primaryIcon="industry" secondaryIcon="hard hat" color="yellow" right>
                  Erection of new commercial buildings such as shophouses, offices, factories and warehouses.
                  Also include buildings for institutional purposes, such places of worship (temples, mosques).
              </ServiceSection>
              <ServiceSection title="Addition and Alteration (A&amp;A)" primaryIcon="building" secondaryIcon="plus" color="green">
                  Major physical renovation of existing building structure. Includes structural extensions, conservation works, changes to building facades, construction of staircases, and structural strengthening.
              </ServiceSection>
              <ServiceSection title="Reinstatement" primaryIcon="warehouse" secondaryIcon="refresh" right>
                  Reinstatement of premises to original condition, typically due to lease agreement covering retail or commercial offices.
                  Usually includes removal of floor finishes, hacking of wall partitions and dismantling of ceiling tops.
              </ServiceSection>
              <ServiceSection title="Civil Engineering" primaryIcon="road" secondaryIcon="hammer" color="grey">
                  Maintenance and construction of infrastructure such as roads, driveways, drainage system and landscaping works.
              </ServiceSection>
              <ServiceSection title="Conservation Works" primaryIcon="university" secondaryIcon="shield" color="blue" right>
                  Undertake restoration works to old buildings in compliance with URA regulations.
                  Preserving heritage and cultural significance.
              </ServiceSection>
              <ServiceSection title="Carpentry" primaryIcon="bed" secondaryIcon="tools" color="brown">
                  Creating custom furnitures and fixtures.
              </ServiceSection>
            </Grid>
            <div className="action">
              <Button basic color='red' as={Link} to={'/contact/'}>Work with us today</Button>
            </div>
          </div>
        </section>

        <section className="content description">
          <div>
            <p>With over 20 years of experience in both residential and commercial projects, Jinmac Pte Ltd portfolio runs the spectrum for various building types. They includes: residential terrace, semi-detached, bungalows and strata housing; or commercial offices, warehouses and factories; or non-commercial projects such as temples and heritage buildings.</p>
            <p>As a BCA-licensed <URL href={url}>General Builder Class 1 (GB1)</URL> contractor, Jinmac Pte Ltd has undertaken many different levels of general building and civil engineering works. Our services includes:</p>
          </div>
          <div className="action">
            <Button basic color='red' as={Link} to={'/services#featured'}>See our Portfolio</Button>
          </div>
        </section>

        <FeaturedSection projects={featuredArray}/>
        <section className='content projectlist'>
          <div>
            <h2>Project List</h2>
            <ProjectTable category="Conservation"/>
            <ProjectTable category={`Additions & Alterations`}/>
            <ProjectTable category="Building Works"/>
            <div className="action">
              <Button basic color='red' as={Link} to={'/contact/'}>We build we care, Call us</Button>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ServicesPage;